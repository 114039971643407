<template>
  <div class="crowd"></div>
  <div
    class="h-full flex flex-col justify-between relative w-full"
    :class="{ 'safe-top': Capacitor.isNativePlatform() }"
  >
    <BackgroundHighlight />
    <header
      class="mx-5 py-[18px] flex items-center flex-shrink-0 justify-between border-white-10 border-b-[1px] md:border-none"
      :class="{
        'mb-10': route.query.referralBy || route.query.sharedByClub || route.query.sharedByFan,
      }"
    >
      <template
        v-if="!route.query.referralBy && !route.query.sharedByClub && !route.query.sharedByFan"
      >
        <RouterLink to="/"><Logo /></RouterLink>
        <LanguageDropdown class="self-start" />
      </template>
      <div v-else class="flex flex-col gap-5 grow">
        <div class="flex justify-between">
          <Avatar club-logo :url="club?.logo" size="xl"></Avatar>
          <LanguageDropdown class="self-start" />
        </div>
        <div class="flex flex-col grow gap-2">
          <p class="text-2xl italic font-extrabold text-white">{{ club?.name }}</p>
          <p class="font-bold caps text-sm text-accent-500">
            {{ $t('fanvestor.shared-funding-header.powered-by-crowdtransfer') }}
          </p>
        </div>
      </div>
    </header>
    <div class="flex flex-col flex-grow mx-5 md:max-w-[768px] md:items-center">
      <div class="flex-grow flex md:items-center md:w-[368px] transition-all">
        <div class="flex-grow">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Capacitor } from '@capacitor/core'
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import Avatar from '@/components/common/avatar/Avatar.vue'
import LanguageDropdown from '@/components/common/language-dropdown/LanguageDropdown.vue'
import BackgroundHighlight from '@/components/page/BackgroundHighlight.vue'
import Footer from '@/components/page/Footer.vue'
import Logo from '@/components/page/Logo.vue'
import { ClubReadModel } from '@/generated/api'
import { useFanvestorsClubsStore } from '@/modules/fanvestor/store/clubs'

const fanvestorsClubsStore = useFanvestorsClubsStore()
const route = useRoute()
const club = ref<ClubReadModel>()

onBeforeMount(async () => {
  const clubID = route.query.clubId ?? route.query.referralBy ?? route.query.sharedByClub
  if (clubID) {
    club.value = await fanvestorsClubsStore.getClub(+clubID)
  }
})
</script>
<style scoped lang="postcss">
.crowd {
  background-image: url('@/assets/images/crowd.png');
  @apply absolute -z-10 right-0 top-0 bottom-0 bg-cover hidden md:block;
  width: calc(100% - 768px);
}
</style>
