import type { RouteLocationNormalized } from 'vue-router'

import { ApiError } from '@/generated/api'
import { UserRole } from '@/modules/auth/types'
import { FANVESTOR } from '@/modules/fanvestor/routes'

import { CLUB_PROFILE, EMAIL_VERIFICATION, LOGIN } from './routes'
import { useAuthStore } from './store'

export const authMiddleware = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const loginRoute = {
    name: LOGIN,
    query: { redirect_to: to.fullPath !== '/' ? to.fullPath : undefined },
  }

  const store = useAuthStore()

  try {
    const user = await store.fetchMe()

    if (to.name === EMAIL_VERIFICATION) {
      return await store
        .verifyEmail(to.query.verification_code as string)
        .then(() =>
          to.query.redirect_path ? { path: to.query.redirect_path } : { name: FANVESTOR }
        )
        .catch(() => ({ name: LOGIN }))
    }

    if (!to.meta.auth) {
      return true
    }

    if (user && to.name === LOGIN) {
      return { path: from.path }
    }

    if (
      (user?.role === UserRole.ClubUser && !!to.meta.authFanvestor) ||
      (user?.role === UserRole.FanVestor && !!to.meta.authClub) ||
      (user?.role === UserRole.ClubUser &&
        +to.params.clubId !== user.club_id &&
        to.name !== CLUB_PROFILE)
    ) {
      return { path: from.path }
    }

    if (!user) {
      store.logout()
      return loginRoute
    }
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.status >= 500) {
        return '/500'
      }
      if ([401, 403].includes(e?.status)) {
        store.logout()
        return loginRoute
      }
    }
    throw e
  }
  return true
}
