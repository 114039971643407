import type { RouteRecordRaw } from 'vue-router'

import LayoutWithoutLogoAndHighlightBG from '@/layouts/LayoutWithoutLogoAndHighlightBG.vue'

import Views from './views/Index.vue'

export const FANVESTOR = 'fanvestor'
export const FUNDS = 'fanvestor.funds'
export const CLUBS = 'fanvestor.clubs'
export const PORTFOLIO = 'fanvestor.portfolio'
export const TRANSFERS = 'fanvestor.funds.transfers'
export const PLAYERS = 'fanvestor.funds.players'
export const COMING_SOON = 'fanvestor.funds.coming-soon'
export const TRANSFER = 'fanvestor.funds.transfer'
export const PLAYER = 'fanvestor.funds.player'
export const TEAM = 'fanvestor.funds.team'
export const FUNDING = 'fanvestor.funds.funding'
export const FUNDING_CHECKOUT = 'fanvestor.funds.funding.checkout'
export const FUNDING_PROCESSING = 'fanvestor.funds.funding.processing'
export const FUNDING_COMPLETE = 'fanvestor.funds.funding.complete'
export const PORTFOLIO_IN_FUNDING = 'fanvestor.portfolio.in-funding'
export const PORTFOLIO_CONFIRMED = 'fanvestor.portfolio.confirmed'
export const PORTFOLIO_FUNDING = 'fanvestor.portfolio.funding'

export const CLUB = 'fanvestor.clubs.club'

export default [
  {
    name: FANVESTOR,
    path: '/fanvestor',
    component: Views,
    redirect: { name: PORTFOLIO },
    children: [
      {
        path: 'funds',
        name: FUNDS,
        component: () => import('./views/Fundings.vue'),
      },
      {
        path: 'funds/transfers',
        name: TRANSFERS,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/AllTransferFundings.vue'),
      },
      {
        path: 'funds/players',
        name: PLAYERS,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/AllPlayerFundings.vue'),
      },
      {
        path: 'funds/coming-soon',
        name: COMING_SOON,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/AllComingSoonFundings.vue'),
      },
      {
        path: 'clubs',
        name: CLUBS,
        component: () => import('./views/AllClubs.vue'),
      },
      {
        path: 'funds/transfer/:fundingId',
        name: TRANSFER,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/FundingDetails.vue'),
      },
      {
        path: 'funds/player/:fundingId',
        name: PLAYER,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/FundingDetails.vue'),
      },
      {
        path: 'funds/team/:fundingId',
        name: TEAM,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/FundingDetails.vue'),
      },
      {
        path: 'funds/funding/:fundingId/transaction/:transactionId',
        name: FUNDING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
          authFanvestor: true,
        },
        props: true,
        component: () => import('./views/FundFunding.vue'),
      },
      {
        path: 'funds/funding/:fundingId/transaction/:transactionId/processing',
        name: FUNDING_PROCESSING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
          authFanvestor: true,
        },
        props: true,
        component: () => import('./views/FundFundingPrecessing.vue'),
      },
      {
        path: 'funds/funding/:fundingId/transaction/:transactionId/checkout',
        name: FUNDING_CHECKOUT,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
          authFanvestor: true,
        },
        props: true,
        component: () => import('./views/FundFundingCheckout.vue'),
      },
      {
        path: 'funds/funding/:fundingId/transaction/:transactionId/complete',
        name: FUNDING_COMPLETE,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
          authFanvestor: true,
        },
        props: true,
        component: () => import('./views/FundFundingComplete.vue'),
      },
      {
        path: 'clubs/:id',
        name: CLUB,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/ClubDetails.vue'),
      },
      {
        path: 'portfolio',
        name: PORTFOLIO,
        meta: {
          auth: true,
          authFanvestor: true,
        },
        component: () => import('./views/Portfolio.vue'),
      },
      {
        path: 'portfolio/in-funding',
        name: PORTFOLIO_IN_FUNDING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
          authFanvestor: true,
        },
        props: true,
        component: () => import('./views/AllInFundingFundings.vue'),
      },
      {
        path: 'portfolio/confirmed',
        name: PORTFOLIO_CONFIRMED,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
          authFanvestor: true,
        },
        props: true,
        component: () => import('./views/AllConfirmedPortfolioFundings.vue'),
      },
      {
        path: '/portfolio/funding/:fundingId',
        name: PORTFOLIO_FUNDING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
          authFanvestor: true,
        },
        props: true,
        component: () => import('./views/ConfirmedPortfolioFunding.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
