import type { RouteRecordRaw } from 'vue-router'

import LayoutWithoutHighlightBG from '@/layouts/LayoutWithoutHighlightBG.vue'
import LayoutWithoutLogoAndHighlightBG from '@/layouts/LayoutWithoutLogoAndHighlightBG.vue'

import Views from './views/Index.vue'

export const CLUB = 'club'
export const DASHBOARD = 'club.dashboard'
export const CLUB_TAB = 'club.club'
export const NEW_TRANSFER = 'club.new-transfer'
export const CONFIRM_FUNDING = 'club.confirm-funding-details'
export const CONFIRMED_FUNDING = 'club.confirmed-funding-details'
export const SOCIAL_PERK = 'club.confirmed-funding.social-perk'
export const FANVESTORS = 'club.transfer.fanvestors'
export const NEW_PLAYER_FUNDING = 'club.fund-player'
export const NEW_TEAM_FUNDING = 'club.fund-team'

export default [
  {
    name: CLUB,
    path: '/club/:clubId',
    component: Views,
    redirect: { name: DASHBOARD },
    props: true,
    meta: {
      auth: true,
      authClub: true,
    },
    children: [
      {
        path: 'dashboard',
        name: DASHBOARD,
        component: () => import('./views/Dashboard.vue'),
      },
      {
        path: 'club',
        name: CLUB_TAB,
        meta: {
          layout: LayoutWithoutHighlightBG,
        },
        component: () => import('./views/MyClub.vue'),
      },
      {
        path: 'new-transfer/:transferId',
        name: NEW_TRANSFER,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/NewTransfer.vue'),
      },
      {
        path: 'confirm-funding-details/:transferId',
        name: CONFIRM_FUNDING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/ConfirmFunding.vue'),
      },
      {
        path: 'fund-player/:transferId',
        name: NEW_PLAYER_FUNDING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/NewPlayerFunding.vue'),
      },
      {
        path: 'fund-team/:transferId',
        name: NEW_TEAM_FUNDING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/NewTeamFunding.vue'),
      },
      {
        path: 'transfer/:transferId/fanvestors',
        name: FANVESTORS,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        component: () => import('./views/Fanvestors.vue'),
      },
      {
        path: 'confirmed-funding-details/:transferId',
        name: CONFIRMED_FUNDING,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/ConfirmedFundingDetails.vue'),
      },
      {
        path: 'confirmed-funding/:transferId/social-perk/:socialPerkId',
        name: SOCIAL_PERK,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
        },
        props: true,
        component: () => import('./views/SocialPerk.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
