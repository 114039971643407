/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomTokenObtainPairRequest } from '../models/CustomTokenObtainPairRequest';
import type { TokenPairCreateResponse } from '../models/TokenPairCreateResponse';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { TokenRefreshRequest } from '../models/TokenRefreshRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TokenService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @returns TokenPairCreateResponse
     * @throws ApiError
     */
    public tokenCreate({
        requestBody,
    }: {
        requestBody: CustomTokenObtainPairRequest,
    }): CancelablePromise<TokenPairCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @returns TokenRefresh
     * @throws ApiError
     */
    public tokenRefreshCreate({
        requestBody,
    }: {
        requestBody: TokenRefreshRequest,
    }): CancelablePromise<TokenRefresh> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/token/refresh/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
