import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useApiClient } from '@/api'

export type ClubsFilters = {
  league: string[] | null
  open_transfers_from: string | null
  open_transfers_to: string | null
  active_players_from: string | null
  active_players_to: string | null
}

export const initialClubFilters = {
  league: null,
  open_transfers_from: null,
  open_transfers_to: null,
  active_players_from: null,
  active_players_to: null,
}

export const useFanvestorsClubsStore = defineStore('fanvestor-clubs', () => {
  const apiClient = useApiClient()
  const filters = ref<ClubsFilters>(initialClubFilters)

  async function getAllClubs(query: Object = {}) {
    return apiClient.clubs.clubsList({
      ...query,
      limit: 100,
    })
  }

  async function getClub(clubId: string | number) {
    return apiClient.clubs.clubsRetrieve({ id: +clubId })
  }

  async function addClubToFavorites(clubId: string | number) {
    return apiClient.clubs.clubsFavoredClubsCreate({ clubPk: +clubId })
  }

  async function removeClubFromFavorites(clubId: string | number) {
    return apiClient.clubs.clubsFavoredClubsDestroy({ clubPk: +clubId })
  }

  async function getFavoredClubs(query: Object = {}) {
    return apiClient.clubs.clubsFavoredClubsList({ ...query, limit: 100 })
  }

  async function getClubFansCount(clubId: string | number) {
    return apiClient.transfers.transfersFanvestorsCountRetrieve({ clubPk: String(clubId) })
  }

  function updateFilters(updatedFilters: ClubsFilters) {
    filters.value = updatedFilters
  }

  return {
    getAllClubs,
    getClub,
    addClubToFavorites,
    removeClubFromFavorites,
    getFavoredClubs,
    getClubFansCount,
    updateFilters,
    filters,
  }
})
