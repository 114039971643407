<template>
  <nav class="main-nav scroll-invisible" ref="el">
    <RouterLink ref="dashboard" :to="{ name: DASHBOARD }">{{
      $t('club.main-nav.dashboard')
    }}</RouterLink>
    <Separator />
    <RouterLink ref="club" :to="{ name: CLUB_TAB }">{{ $t('club.main-nav.club') }}</RouterLink>
  </nav>
</template>
<script lang="ts" setup>
import { useScroll } from '@vueuse/core'
import { nextTick, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import Separator from '@/assets/images/main-nav-separator.svg?component'
import { CLUB_TAB, DASHBOARD } from '@/modules/club/routes'

const dashboard = ref<{ $el: HTMLElement }>()
const club = ref<{ $el: HTMLElement }>()
const el = ref<HTMLElement>()
const route = useRoute()

const { x } = useScroll(el, { behavior: 'smooth' })

function centerActiveLink() {
  const links = [dashboard.value, club.value]
  links.forEach((link) => {
    if (link && link.$el && link.$el.classList.contains('router-link-active')) {
      const width = el.value?.clientWidth || 100
      const offset = (width - link.$el.clientWidth) / 2
      x.value = link.$el.offsetLeft - offset
    }
  })
}

onMounted(() => {
  centerActiveLink()
})
watch(route, () => {
  nextTick(() => {
    centerActiveLink()
  })
})
</script>
<style scoped lang="postcss">
.main-nav {
  @apply flex items-center h-14 text-4xl font-extrabold italic flex-nowrap whitespace-nowrap gap-5 text-white-20 px-5 sm:!overflow-x-visible;
  * {
    @apply shrink-0;
  }

  & > a.router-link-active {
    @apply text-white hover:text-white;
  }
  & > a:hover {
    @apply text-white-60;
  }
}
</style>
